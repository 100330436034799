import getRegime from './getRegime';

export const getRegimeCompliantObjectById = (
    business,
    regimeId,
    compliantId,
) => {
    const regime = getRegime(business.regimes, regimeId);

    if (regime) {
        const compliantPossibleRegime = regime.values.find(
            (item) => item.compliant === compliantId,
        );
        if (compliantPossibleRegime) {
            return compliantPossibleRegime;
        } else {
            return false;
        }
    }
    return false;
};

export default getRegimeCompliantObjectById;
