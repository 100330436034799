import isIterable from '~/src/lib/isIterable';

export const getOptionValue = (business, optionId, optionValueId) => {
    if (business && isIterable(business?.options)) {
        const option = business.options.find((o) => o.id === optionId);

        if (option && option.values) {
            const optionValue = option.values.find(
                (o) => o.id === optionValueId,
            );
            if (optionValue) {
                return optionValue;
            }
        }
    }

    return false;
};

export default getOptionValue;
