import {
    PRODUCT_SET_DEFAULT,
    PRODUCT_REMOVE_OPTION_VALUE,
    PRODUCT_SET_OPTION_UNIQUE_CHOICE,
    PRODUCT_SET_OPTION_UNIQUE_EXTRA,
    PRODUCT_SET_OPTION_UNIQUE_EXTRA_PLUS,
    PRODUCT_SET_OPTION_MULTIPLE_EXTRA,
    PRODUCT_SET_OPTION_MULTIPLE_EXTRA_PLUS,
    PRODUCT_SET_BASE_GROUP_OPTION,
    PRODUCT_SET_OPTION_UNIQUE_CHOICE_FOR_A_GROUP_OPTION,
    PRODUCT_SET_OPTION_UNIQUE_EXTRA_FOR_A_GROUP_OPTION,
    PRODUCT_SET_OPTION_UNIQUE_EXTRA_PLUS_FOR_A_GROUP_OPTION,
    PRODUCT_SET_OPTION_MULTIPLE_EXTRA_FOR_A_GROUP_OPTION,
    PRODUCT_SET_OPTION_MULTIPLE_EXTRA_PLUS_FOR_A_GROUP_OPTION,
    PRODUCT_SET_OPTION_REPLACE,
    HANDLE_REMOVAL_INGREDIENTS,
    BUILD_COMPOSITIONS,
    COMPOSITION_REMOVE_ONE,
    COMPOSITION_ADD_ONE,
    SET_COMPOSITION_QUANTITY,
    CHANGE_SLICE_PRICE,
    RESET_CART,
    CART_PRODUCT_ADD,
    CART_PRODUCT_REMOVE_BY_PRODUCT_ID,
    CART_PRODUCT_REMOVE,
    CART_PRODUCT_REMOVE_ONE,
    CART_PRODUCT_ADD_ONE,
    CART_PRODUCT_UPDATE_QUANTITY,
    SET_SCHEDULE,
    SET_CART_USER_INFO,
    NEW_BOOKING_REQUEST,
    FORMULA_CURRENT,
    FORMULA_ITEM_REMOVE,
    FORMULA_ITEM_ADD,
    FORMULA_HANDLE_REMOVAL_INGREDIENTS,
    CONFIRM_FORMULA,
    DELETE_CART_FORMULA,
    REMOVE_ONE_QUANTITY_TO_FORMULA,
    ADD_ONE_QUANTITY_TO_FORMULA,
    SET_SELECTED_REGIMES,
    SET_CURRENCY,
    SET_BROWSER_LANG,
    SET_PLANNING,
    ORDER_SENDING,
    BOOKING_SENDING,
    CART_SET_COMMENT,
    CART_SET_CUSTOMER_FIRSTNAME,
    COMPOSITION_SET_COMMENT,
    CART_SET_NB_PEOPLE,
    SET_VENUE,
    COMPOSITION_RANDOM,
    SET_ELIOR_MENU_TYPE,
    PRODUCT_FORMULA_SET_OPTION_UNIQUE_CHOICE,
    PRODUCT_FORMULA_SET_OPTION_UNIQUE_EXTRA,
    PRODUCT_FORMULA_SET_OPTION_UNIQUE_EXTRA_PLUS,
    PRODUCT_FORMULA_SET_OPTION_MULTIPLE_EXTRA,
    PRODUCT_FORMULA_SET_OPTION_MULTIPLE_EXTRA_PLUS,
    COMPOSITION_FORMULA_ADD_OPTION,
    COMPOSITION_FORMULA_REMOVE_OPTION,
    REDUCED_REGIME,
    PRODUCT_DISCOUNT_REMOVE,
    PRODUCT_DISCOUNT_ADD,
    SET_SELECTED_MENU,
    SET_MENU_TYPE,
    SET_LANGUAGES_LOADED,
} from './constants';

const actions = {
    setReducedRegime: (payload) => ({
        type: REDUCED_REGIME,
        payload,
    }),

    setProductDefault: (payload) => ({
        type: PRODUCT_SET_DEFAULT,
        payload,
    }),

    removeOptionValue: (payload) => ({
        type: PRODUCT_REMOVE_OPTION_VALUE,
        payload,
    }),

    setPlanning: (payload) => ({
        type: SET_PLANNING,
        payload,
    }),

    setProductOptionUniqueChoice: (payload) => ({
        type: PRODUCT_SET_OPTION_UNIQUE_CHOICE,
        payload,
    }),

    setProductOptionUniqueExtra: (payload) => ({
        type: PRODUCT_SET_OPTION_UNIQUE_EXTRA,
        payload,
    }),

    setProductOptionUniqueExtraPlus: (payload) => ({
        type: PRODUCT_SET_OPTION_UNIQUE_EXTRA_PLUS,
        payload,
    }),

    setProductOptionMultipleExtra: (payload) => ({
        type: PRODUCT_SET_OPTION_MULTIPLE_EXTRA,
        payload,
    }),

    setProductOptionMultipleExtraPlus: (payload) => ({
        type: PRODUCT_SET_OPTION_MULTIPLE_EXTRA_PLUS,
        payload,
    }),

    setBaseGroupOption: (payload) => ({
        type: PRODUCT_SET_BASE_GROUP_OPTION,
        payload,
    }),

    setProductOptionUniqueChoiceForAGroupOption: (payload) => ({
        type: PRODUCT_SET_OPTION_UNIQUE_CHOICE_FOR_A_GROUP_OPTION,
        payload,
    }),
    setProductOptionUniqueExtraForAGroupOption: (payload) => ({
        type: PRODUCT_SET_OPTION_UNIQUE_EXTRA_FOR_A_GROUP_OPTION,
        payload,
    }),
    setProductOptionUniqueExtraPlusForAGroupOption: (payload) => ({
        type: PRODUCT_SET_OPTION_UNIQUE_EXTRA_PLUS_FOR_A_GROUP_OPTION,
        payload,
    }),
    setProductOptionMultipleExtraForAGroupOption: (payload) => ({
        type: PRODUCT_SET_OPTION_MULTIPLE_EXTRA_FOR_A_GROUP_OPTION,
        payload,
    }),
    setProductOptionMultipleExtraPlusForAGroupOption: (payload) => ({
        type: PRODUCT_SET_OPTION_MULTIPLE_EXTRA_PLUS_FOR_A_GROUP_OPTION,
        payload,
    }),
    setProductOptionReplace: (payload) => ({
        type: PRODUCT_SET_OPTION_REPLACE,
        payload,
    }),

    buildCompositions: (payload) => ({
        type: BUILD_COMPOSITIONS,
        payload,
    }),

    compositionRemoveOne: (payload) => ({
        type: COMPOSITION_REMOVE_ONE,
        payload,
    }),

    compositionAddOne: (payload) => ({
        type: COMPOSITION_ADD_ONE,
        payload,
    }),

    setCompositionQuantity: (payload) => ({
        type: SET_COMPOSITION_QUANTITY,
        payload,
    }),

    handleRemovalIngredients: (payload) => ({
        type: HANDLE_REMOVAL_INGREDIENTS,
        payload,
    }),

    cartProductAdd: (payload) => ({
        type: CART_PRODUCT_ADD,
        payload,
    }),

    cartProductRemoveByProductId: (payload) => ({
        type: CART_PRODUCT_REMOVE_BY_PRODUCT_ID,
        payload,
    }),

    cartProductRemove: (payload) => ({
        type: CART_PRODUCT_REMOVE,
        payload,
    }),

    cartProductRemoveOne: (payload) => ({
        type: CART_PRODUCT_REMOVE_ONE,
        payload,
    }),

    cartProductAddOne: (payload) => ({
        type: CART_PRODUCT_ADD_ONE,
        payload,
    }),

    cartProductUpdateQuantity: (payload) => ({
        type: CART_PRODUCT_UPDATE_QUANTITY,
        payload,
    }),

    setFormulaCurrentUuid: (payload) => ({
        type: FORMULA_CURRENT,
        payload,
    }),

    formulaItemAdd: (payload) => ({
        type: FORMULA_ITEM_ADD,
        payload,
    }),

    formulaItemRemove: (payload) => ({
        type: FORMULA_ITEM_REMOVE,
        payload,
    }),

    confirmFormula: (payload) => ({
        type: CONFIRM_FORMULA,
        payload,
    }),

    deleteCartFormula: (payload) => ({
        type: DELETE_CART_FORMULA,
        payload,
    }),

    removeOneQuantityToFormula: (payload) => ({
        type: REMOVE_ONE_QUANTITY_TO_FORMULA,
        payload,
    }),

    addOneQuantityToFormula: (payload) => ({
        type: ADD_ONE_QUANTITY_TO_FORMULA,
        payload,
    }),

    formulaHandleRemovalIngredients: (payload) => ({
        type: FORMULA_HANDLE_REMOVAL_INGREDIENTS,
        payload,
    }),

    resetCart: () => ({
        type: RESET_CART,
    }),

    setSchedule: (payload) => ({
        type: SET_SCHEDULE,
        payload,
    }),

    setCartUserInfo: (payload) => ({
        type: SET_CART_USER_INFO,
        payload,
    }),

    newBookingRequest: (payload) => ({
        type: NEW_BOOKING_REQUEST,
        payload,
    }),

    setSelectedRegimes: (payload) => ({
        type: SET_SELECTED_REGIMES,
        payload,
    }),

    setCurrency: (payload) => ({
        type: SET_CURRENCY,
        payload,
    }),

    setBrowserLang: (payload) => ({
        type: SET_BROWSER_LANG,
        payload,
    }),

    changeSlicePrice: (payload) => ({
        type: CHANGE_SLICE_PRICE,
        payload,
    }),
    orderSending: (payload) => ({
        type: ORDER_SENDING,
        payload,
    }),
    bookingSending: (payload) => ({
        type: BOOKING_SENDING,
        payload,
    }),
    cartSetComment: (payload) => ({
        type: CART_SET_COMMENT,
        payload,
    }),
    cartSetCustomerFirstname: (payload) => ({
        type: CART_SET_CUSTOMER_FIRSTNAME,
        payload,
    }),

    compositionSetComment: (payload) => ({
        type: COMPOSITION_SET_COMMENT,
        payload,
    }),
    cartSetNbPeople: (payload) => ({
        type: CART_SET_NB_PEOPLE,
        payload,
    }),
    setVenue: (payload) => ({
        type: SET_VENUE,
        payload,
    }),

    compositionRandom: () => ({
        type: COMPOSITION_RANDOM,
    }),

    setEliorMenuType: () => ({
        type: SET_ELIOR_MENU_TYPE,
    }),

    setProductFormulaOptionUniqueChoice: (payload) => ({
        type: PRODUCT_FORMULA_SET_OPTION_UNIQUE_CHOICE,
        payload,
    }),

    setProductFormulaOptionUniqueExtra: (payload) => ({
        type: PRODUCT_FORMULA_SET_OPTION_UNIQUE_EXTRA,
        payload,
    }),

    setProductFormulaOptionUniqueExtraPlus: (payload) => ({
        type: PRODUCT_FORMULA_SET_OPTION_UNIQUE_EXTRA_PLUS,
        payload,
    }),

    setProductFormulaOptionMultipleExtra: (payload) => ({
        type: PRODUCT_FORMULA_SET_OPTION_MULTIPLE_EXTRA,
        payload,
    }),

    setProductFormulaOptionMultipleExtraPlus: (payload) => ({
        type: PRODUCT_FORMULA_SET_OPTION_MULTIPLE_EXTRA_PLUS,
        payload,
    }),

    compositionFormulaAddOption: (payload) => ({
        type: COMPOSITION_FORMULA_ADD_OPTION,
        payload,
    }),

    compositionFormulaRemoveOption: (payload) => ({
        type: COMPOSITION_FORMULA_REMOVE_OPTION,
        payload,
    }),

    productDiscountAdd: (payload) => ({
        type: PRODUCT_DISCOUNT_ADD,
        payload,
    }),

    productDiscountRemove: (payload) => ({
        type: PRODUCT_DISCOUNT_REMOVE,
        payload,
    }),

    setSelectedMenu: (payload) => ({
        type: SET_SELECTED_MENU,
        payload,
    }),

    setMenuType: (payload) => ({
        type: SET_MENU_TYPE,
        payload,
    }),

    setLanguagesLoaded: () => ({
        type: SET_LANGUAGES_LOADED,
    }),
};
export default actions;
