import {
    LOAD_RESTAURANT,
    LOAD_RESTAURANT_SUCCESS,
    UPDATE_RESTAURANT,
    SET_RESTAURANT,
    UPDATE_PRODUCT,
} from './constants';

const actions = {
    loadBusiness: (payload) => ({
        type: LOAD_RESTAURANT,
        payload,
    }),
    loadBusinessSuccess: (payload) => ({
        type: LOAD_RESTAURANT_SUCCESS,
        payload,
    }),
    updateBusiness: (payload) => ({
        type: UPDATE_RESTAURANT,
        payload,
    }),
    setBusiness: (payload) => ({
        type: SET_RESTAURANT,
        payload,
    }),
    updateProduct: (payload) => ({
        type: UPDATE_PRODUCT,
        payload,
    }),
};
export default actions;
