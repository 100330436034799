import { SET_BOOKING, FETCH_BOOKING } from './constants';

const actions = {
    setBooking: (payload) => ({
        type: SET_BOOKING,
        payload,
    }),
    fetchBooking: (payload) => ({
        type: FETCH_BOOKING,
        payload,
    }),
};
export default actions;
