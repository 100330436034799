import { combineReducers } from 'redux';
import { routerReducer } from 'connected-next-router';
import app, { initialState as appState } from '~/redux/app/reducer';
import common, { initialState as commonState } from '~/redux/common/reducer';
import business from '~/redux/business/reducer';
import lang from '~/redux/lang/reducer';
import cookie from '~/redux/cookie/reducer';
import menu from '~/redux/menu/reducer';
import order from '~/redux/order/reducer';
import booking from '~/redux/booking/reducer';
import message from '~/redux/message/reducer';
import orderStatus from '~/redux/orderStatus/reducer';
import user, { initialState as userState } from '~/redux/user/reducer';

import langUniversal from '~/redux/langUniversal/reducer';

/*
export const reducerState = {
    app: appState,
    common: commonState,
    router: initialRouterState
};

export const reducerState1 = {
    business: businessState,
};
*/

export default combineReducers({
    app,
    common,
    lang,
    cookie,
    business,
    order,
    booking,
    message,
    orderStatus,
    user,
    menu,
    langUniversal,
    router: routerReducer,
});
