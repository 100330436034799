export const checkMaintenance = (business, ip) => {
    console.log(333333);
    console.log(typeof business.info);
    if (!business) {
        return true;
    }

    if (typeof business.info === 'undefined') {
        return true;
    }

    //ip = '89.159.69.36';
    console.log('checkMaintenance');
    console.log(ip);
    console.log(business.info.maintenance);
    console.log(business.info.maintenance_by_client);
    console.log(business.info.authorized_ip);

    if (
        business &&
        business.info &&
        typeof business.info.maintenance === 'number' &&
        business.info.maintenance === 1
    ) {
        return true;
    }

    if (
        business &&
        business.info &&
        typeof business.info.maintenance_by_client === 'number' &&
        business.info.maintenance_by_client === 1
    ) {
        if (
            typeof ip === 'string' &&
            ip.length > 5 &&
            typeof business.info.authorized_ip === 'string' &&
            business.info.authorized_ip.length > 5
        ) {
            //console.log('BBBBBB');

            const tmp = business.info.authorized_ip.split(',');
            //console.log(tmp);

            if (tmp.includes(ip)) {
                //console.log('CCCC');
                return false;
            }
        }

        return true;
    }
    return false;
};

export default checkMaintenance;
