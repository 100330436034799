export function getItem(field) {
    try {
        if (typeof localStorage === 'object') {
            return localStorage.getItem(field);
        }
    } catch (e) {
        console.log('ERROR LOCASTORAGE');
        console.log(e);
        return false;
    }

    return false;
}

export function setItem(field, newValue) {
    try {
        if (typeof localStorage === 'object') {
            return localStorage.setItem(field, newValue);
        }
    } catch (e) {
        console.log('ERROR LOCASTORAGE');
        console.log(e);
        return false;
    }

    return false;
}

export function removeItem(field) {
    try {
        if (typeof localStorage === 'object') {
            return localStorage.removeItem(field);
        }
    } catch (e) {
        console.log('ERROR LOCASTORAGE');
        console.log(e);
        return false;
    }
}
