import { toast } from 'react-toastify';
import isMobile from '~/src/lib/isMobile';

export default function app(msg, type = 'success') {
    if (type === 'error') {
        toast.error(msg, {
            position: isMobile() === true ? 'bottom-center' : 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            //pauseOnHover: true,
            //draggable: true
        });
    } else {
        toast.success(msg, {
            position: isMobile() === true ? 'bottom-center' : 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            //pauseOnHover: true,
            //draggable: true
        });
    }
}
