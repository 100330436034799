import Cookies from 'js-cookie';

export default function translateMsg(key = 'manager', v) {
    if (
        typeof window === 'object' &&
        window.customMessages &&
        window.customMessages[v]
    ) {
        return window.customMessages[v];
    }

    if (typeof localStorage !== 'object') {
        return '';
    }

    if (typeof localStorage.getItem !== 'function') {
        return '';
    }

    if (typeof localStorage === 'object' && Cookies && localStorage) {
        const customTranslation = localStorage.getItem('babel_traductions');
        //const lang = localStorage.getItem("lang");
        const lang = Cookies.get('NEXT_LOCALE');
        if (customTranslation && lang) {
            const customTranslationJson = JSON.parse(customTranslation);
            const match = customTranslationJson.find((o) => o.k === v);
            if (match && match.name && match.name[lang]) {
                //console.log(8888);
                //console.log(customTranslation);
                //console.log(customTranslationJson);
                //console.log(match);
                return match.name[lang];
            }
        }
    }

    // show babel key ?
    const bb = localStorage.getItem('babel');

    if (typeof bb === 'string') {
        return `${key}:${v}`;
    }

    const kV2 = `foound_${key}`;

    //console.log(key);
    //console.log(v);

    const localeTranslation = localStorage.getItem(kV2);
    if (localeTranslation) {
        const localeTranslationJson = JSON.parse(localeTranslation);
        //console.log(localeTranslationJson);
        //console.log(v);
        if (
            localeTranslationJson &&
            typeof localeTranslationJson[v] === 'string'
        ) {
            return localeTranslationJson[v];
        }
    }

    return v;
}
