import update from 'immutability-helper';
import MultiSort from 'multi-sort';
import { SET_LANG } from './constants';

// The initial state of the App
export const initialState = 'fr';

export default function langReducer(state = initialState, action) {
    //export default (state = initialState, action) => {
    switch (action.type) {
        case SET_LANG: {
            //console.log('SET LANG');
            //console.log(action);

            if (action.payload === 'id') {
                return 'fr';
            }
            return action.payload;
        }

        default:
            return state;
    }
}
