export const isOptionMandatory = (option) => {
    if (
        option &&
        option.minimum &&
        typeof option.minimum === 'number' &&
        option.minimum > 0
    ) {
        return true;
    }

    return false;
};

export default isOptionMandatory;
