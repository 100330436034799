import { SET_ORDER, FETCH_ORDER } from './constants';

const actions = {
    setOrder: (payload) => ({
        type: SET_ORDER,
        payload,
    }),
    fetchOrder: (payload) => ({
        type: FETCH_ORDER,
        payload,
    }),
};
export default actions;
