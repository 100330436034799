import isHandleOptionValue from '~/src/lib/v2/option/isHandleOptionValue';

export const getOptionWithFilteredValues = (
    business,
    option,
    menuType,
    common,
    qrcode,
    selectedRegimes,
) => {
    const o = JSON.parse(JSON.stringify(option));
    o.values = [];

    option.values.forEach((optionValue) => {
        const status = `${menuType}_status`;

        if (
            typeof optionValue.active === 'number' &&
            optionValue.active === 1
        ) {
            //console.log(2233);
            //console.log(optionValue);
            if (optionValue.available === 0) {
                return false;
            }

            //console.log(123);
            //console.log(optionValue);
            //console.log(status);

            if (
                (+business.info.handle_price_takeaway === 0 &&
                    +business.info.handle_price_delivery === 0) ||
                optionValue[status] === 1
            ) {
                if (optionValue.type === 1) {
                    o.values.push(optionValue);
                } else {
                    if (
                        isHandleOptionValue(
                            business,
                            optionValue,
                            common,
                            qrcode,
                            menuType,
                            selectedRegimes,
                        ) === true
                    ) {
                        o.values.push(optionValue);
                    }
                }
            }
        }
    });

    return o;
};

export default getOptionWithFilteredValues;
