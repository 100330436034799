export const GREEN = '#56a941';
export const GREEN_LIGTH = '#88db6f';
export const GREEN_DARK = '#1f7910';
export const BLUE = '#4991e3';
export const RED = '#d7392e';
export const DISABLED = '#CCCCCC';

export const GREY1 = '#898A8E';

export const MARGIN_TOP = 48;

export const INSIDE = 'inside';
export const TAKEAWAY = 'takeaway';
export const DELIVERY = 'delivery';

//export const BOX_SHADOW = "0px 1px 7px 0px rgba(0,0,0,0.4)"; tristan
//-webkit-box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.75);
export const BOX_SHADOW = '0px 7px 5px -4px rgba(0,0,0,0.4)';

export const DEFAULT_CUSTOM_BUTTON1 = '#56a941';
export const DEFAULT_CUSTOM_COLOR1 = '#FFFFFF';
export const DEFAULT_CUSTOM_BG1 = '#242b35';
export const DEFAULT_CUSTOM_BG2 = '#383838';
export const DEFAULT_CUSTOM_BG3 = '#23313B';
export const DEFAULT_CUSTOM_BG4 = '#000000';
export const DEFAULT_CUSTOM_RADIUS = 20;
export const DEFAULT_CUSTOM_ICON = '#980000';

export const SITE_MAX_WIDTH = 1024;
export const FLOATING_BOX = 320;

export const HEADER_MAX = 80;
export const HEADER_MIN = 60;
export const HEADER_BG = 'rgba(255, 255, 255, 0)';
export const HEADER_BG1 = 'rgba(255, 255, 255, 1)';
export const HEADER_COLOR = '#FFFFFF';
export const HEADER_COLOR1 = '#000000';

export const HEADER_SPECIAL_DATE = 50;
export const HEADER_ORDER = 50;

export const LOCALES = [
    'ar',
    'cn',
    'de',
    'fr',
    'en',
    'es',
    'hu',
    'id',
    'it',
    'ja',
    'ko',
    'nl',
    'pt',
    'ru',
];

export const OPTION_VALUE_TYPE_FREE_TEXT = 1;
export const OPTION_VALUE_TYPE_INGREDIENT_ADDED = 2;
export const OPTION_VALUE_TYPE_INGREDIENT_INCLUDED = 3;
export const OPTION_VALUE_TYPE_PRODUCT = 4;

export const UNAVAILABLE = 0;
export const AVAILABLE = 1;
export const WILL_AVAILABLE = 2;

export const COOKIE1 = '_qrcode1';
export const COOKIE2 = '_qrcode2';

export const COOKIE_QRCODE1_DURATION = 60 * 60 * 4; // en secondes, 4 heures
export const COOKIE_QRCODE2_DURATION = 60 * 60 * 1; // en secondes, 1 heure
