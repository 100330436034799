import { SET_USER, CHANGE_EMAIL, UPDATE_ENABLE_ALERT } from './constants';

const actions = {
    setUser: (payload) => ({
        type: SET_USER,
        payload,
    }),

    changeEmail: (payload) => ({
        type: CHANGE_EMAIL,
        payload,
    }),
    updateEnableAlert: (payload) => ({
        type: UPDATE_ENABLE_ALERT,
        payload,
    }),
};

export default actions;
