import update from 'immutability-helper';
import { SET_USER, CHANGE_EMAIL, UPDATE_ENABLE_ALERT } from './constants';

export const initialState = false;

export default function userReducer(state = initialState, action) {
    //export default (state = initialState, action) => {
    switch (action.type) {
        case SET_USER: {
            console.log('REDUCER SET USER');
            console.log(action.payload);

            if (typeof action.payload !== 'undefined') {
                return action.payload;
            }
            return state;
        }

        case CHANGE_EMAIL: {
            return { ...state, email: action.payload };
        }

        case 'LOGOUT': {
            console.log('REDUCER LOGOUT');
            return initialState;
        }

        case UPDATE_ENABLE_ALERT: {
            console.log(UPDATE_ENABLE_ALERT);
            console.log(action.payload);
            return { ...state, zone_email_alerts: action.payload };
        }

        default:
            return state;
    }
}
