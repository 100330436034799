import isOptionValueAvailable from '~/src/lib/v2/option/isOptionValueAvailable';
import isOptionValueActive from '~/src/lib/v2/option/isOptionValueActive';

export const isHandleOptionValue = (
    business,
    optionValue,
    common,
    qrcode,
    menuType,
    selectedRegimes,
) => {
    let response = true;

    //console.log('optionValue.type');
    //console.log(optionValue.type);

    if (
        optionValue.type !== 1 &&
        optionValue.business_product_id &&
        optionValue.business_product_id > 0
    ) {
        //https://trello.com/c/z4sD3NJZ/1082-h%C3%A9ritage-de-loption

        if (isOptionValueActive(business, optionValue) === false) {
            response = false;
        }

        if (isOptionValueAvailable(business, optionValue) === false) {
            response = false;
        }

        if (
            +business.info.handle_price_takeaway === 1 &&
            optionValue &&
            optionValue[`${menuType}_status`] === 0 &&
            typeof optionValue[`${menuType}_status`] === 'number'
        ) {
            response = false;
        }

        if (
            +business.info.handle_price_delivery === 1 &&
            optionValue &&
            optionValue[`${menuType}_status`] === 0 &&
            typeof optionValue[`${menuType}_status`] === 'number'
        ) {
            response = false;
        }

        /*
        const product = getProduct(business.products, "id", optionValue.business_product_id);

        if(product) {
            response = isHandleProduct(
                qrcode,
                common,
                business,
                product,
                menuType,
                selectedRegimes
            );
        } else {
            response = false;
        }
        */
    }

    return response;
};

export default isHandleOptionValue;
