import { SET_MESSAGE, FETCH_MESSAGE } from './constants';

const actions = {
    setMessage: (payload) => ({
        type: SET_MESSAGE,
        payload,
    }),
    fetchMessage: (payload) => ({
        type: FETCH_MESSAGE,
        payload,
    }),
};
export default actions;
