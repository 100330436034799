import cookie from 'cookie';
import { parseCookies, setCookie, destroyCookie } from 'nookies';
import parseLanguages from '~/src/lib/parseLanguages';
import { LOCALES } from '~/src/config/constants';
import Router from 'next/router';

export const getLang = (req, locale = false) => {
    let lang = 'fr';

    // server side
    if (req && typeof req.headers === 'object') {
        if (req.cookies && req.cookies.NEXT_LOCALE) {
            lang = req.cookies.NEXT_LOCALE;
        } else if (locale && locale !== 'id') {
            lang = locale;
        } else if (req.headers['cookie']) {
            const cookies = cookie.parse(req.headers['cookie']);
            if (cookies['lang']) {
                lang = cookies['lang'];
            }
        } else if (req.headers['accept-language']) {
            const dd = parseLanguages(req.headers['accept-language']);
            if (dd && dd[0] && dd[0].code) {
                //if (['de', 'es', 'fr', 'en', 'it'].includes(dd[0].code)) {
                lang = dd[0].code;
                //}
            }
        }
    } else {
        console.log('KKKKKKKK');
        console.log(Router.locale);

        if (Router.locale === 'it') {
            return 'fr';
        }

        return Router.locale;
        // client side
        const cookies = parseCookies();

        if (cookies['lang']) {
            return cookies['lang'];
        }
    }

    console.log(88888);
    console.log(lang);

    return lang;
};

export default getLang;
