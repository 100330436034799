import update from 'immutability-helper';
import { SET_MESSAGE } from './constants';

export const initialState = [];

const message = (state = initialState, action) => {
    switch (action.type) {
        case SET_MESSAGE: {
            return action.payload;
        }

        default:
            return state;
    }
};

export default message;
