import nextReduxSaga from 'next-redux-saga';
import withRedux from 'next-redux-wrapper';
import {
    createStore,
    applyMiddleware,
    Reducer,
    Middleware,
    compose,
} from 'redux';
//import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducer';
import rootSaga from './saga';

const persistConfig = {
    key: 'nextjs',
    whitelist: ['auth', 'common', 'cookie', 'menu'],
    storage,
};

const configureStore = (initialState = {}, { isServer }) => {
    const sagaMiddleware = createSagaMiddleware();
    const middlewares = [sagaMiddleware];

    if (!isServer) {
        // middlewares.push(logger);
    }

    const reducer = isServer
        ? rootReducer
        : persistReducer(persistConfig, rootReducer);

    const composeEnhancers =
        typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                  // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
              })
            : compose;

    const store = createStore(
        reducer,
        initialState,
        // applyMiddleware(...middlewares),
        composeEnhancers(applyMiddleware(...middlewares)),
    );
    store.sagaTask = sagaMiddleware.run(rootSaga);

    if (!isServer) {
        store.__persistor = persistStore(store);
        if (typeof window === 'object') {
            window.store = store;
        }
    }

    return store;
};

export default (BaseComponent) =>
    withRedux(configureStore)(nextReduxSaga(BaseComponent));
