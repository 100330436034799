import tryFn from '../../utils/tryFn';

export const getCompositionProperty = (composition, type, key, id) => {
    if (
        composition &&
        composition.properties &&
        composition.properties.length > 0
    ) {
        return tryFn(
            () =>
                composition.properties.find(
                    (item) => item[key] === id && item.type === type,
                ),
            false,
        );
    }
    return false;
};

export default getCompositionProperty;
