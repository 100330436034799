import update from 'immutability-helper';
import { SET_LANG_UNIVERSAL } from './constants';

export const initialState = {};

const langUniversal = (state = initialState, action) => {
    switch (action.type) {
        case SET_LANG_UNIVERSAL: {
            return action.payload;
        }

        default:
            return state;
    }
};

export default langUniversal;
