import update from 'immutability-helper';
import {
    LOAD_RESTAURANT_SUCCESS,
    SET_RESTAURANT,
    UPDATE_PRODUCT,
} from './constants';
import getLang from '~/src/lib/getLang';

export const initialState = {};

const business = (state = initialState, action) => {
    switch (action.type) {
        case SET_RESTAURANT: {
            console.log('SET_RESTAURANT');
            return action.payload;
        }
        case LOAD_RESTAURANT_SUCCESS: {
            //console.log('LOAD_RESTAURANT_SUCCESS');
            return action.payload.data;
        }
        case UPDATE_PRODUCT: {
            const lang = getLang();
            const index = state.products.findIndex(
                (p) => p.uuid === action.payload.uuid,
            );

            action.payload.inMenu = 1;
            action.payload.available = +action.payload.available;
            action.payload.name = action.payload.name;
            action.payload.description = action.payload.description;
            action.payload.recipe = action.payload.recipe;

            if (index !== -1) {
                return update(state, {
                    products: { [index]: { $set: action.payload } },
                });
            }

            return state;
        }

        default:
            return state;
    }
};

export default business;
