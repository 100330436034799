import update from 'immutability-helper';
import { SET_BOOKING } from './constants';

export const initialState = [];

const booking = (state = initialState, action) => {
    switch (action.type) {
        case SET_BOOKING: {
            return action.payload;
        }

        default:
            return state;
    }
};

export default booking;
